import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import HeroSection from "../components/common/hero-section"
import SectionBlog from "../components/sections/section-blog"
import Seo from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      imgHero: file(relativePath: { eq: "backgrounds/blog-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgHero = getImage(data.imgHero)
  const imgSrc = getSrc(data.imgHero)

  return (
    <Layout>
      <Seo
        title="Micro-Captive"
        description="Check out the Micro-Captive blog to learn more about 831(b) Plans and industry updates."
        image={imgSrc}
      />
      <HeroSection
        title="MICRO-CAPTIVE"
        imgSrc={imgHero}
      />
      <SectionBlog />
    </Layout>
  )
}

export default IndexPage
