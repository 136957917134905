import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const h2Style = "text-xl md:text-2xl mb-4 font-extrabold"
const pStyle = "text-base"
const dateStyle = "text-xs mb-6"

const SectionBlog = () => (
  <>
    <div className="bg-site-green-light py-12 lg:py-24">
      <div className="relative w-11/12 lg:w-10/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mx-auto">
        <div className="bg-white border border-site-green shadow-lg">
          <div>
            <Link to="/another-abusive-captive">
              <StaticImage
                src="../../images/backgrounds/blog-4.jpg"
                width={1920}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt=""
              />
            </Link>
          </div>

          <div className="p-8">
            <p className={dateStyle}>April 2, 2021</p>
            <Link to="/another-abusive-captive">
              <h2 className={h2Style}>
                Another Abusive Captive Takes a Tumble in Tax Court
              </h2>
            </Link>
            <p className={pStyle}>
              SRA Founder & CEO, Van Carlson, discusses the Caylor Land Ruling.
              In a recent ruling, the US Tax Court again concluded...
            </p>
            <Link to="/another-abusive-captive">
              <button className="w-full bg-site-red hover:bg-red-800 text-sm text-white uppercase tracking-wider mt-6 p-3">
                Read More
              </button>
            </Link>
          </div>
        </div>

        <div className="bg-white border border-site-green shadow-lg">
          <div>
            <Link to="/captive-black-swan">
              <StaticImage
                src="../../images/backgrounds/blog-2.jpg"
                width={1280}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt=""
              />
            </Link>
          </div>

          <div className="p-8">
            <p className={dateStyle}>August 4, 2020</p>
            <Link to="/captive-black-swan">
              <h2 className={h2Style}>
                Using Captives to Insure Against Black Swan Events
              </h2>
            </Link>
            <p className={pStyle}>
              In case you missed it - Sometimes, third-party commercial
              insurance policies are not always enough.
            </p>
            <Link to="/captive-black-swan">
              <button className="w-full bg-site-red hover:bg-red-800 text-sm text-white uppercase tracking-wider mt-6 p-3">
                Read More
              </button>
            </Link>
          </div>
        </div>

        <div className="bg-white border border-site-green shadow-lg">
          <div>
            <Link to="/bad-business-reputation">
              <StaticImage
                src="../../images/backgrounds/blog-6.jpg"
                width={1920}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt=""
              />
            </Link>
          </div>

          <div className="p-8">
            <p className={dateStyle}>October 24, 2018</p>
            <Link to="/bad-business-reputation">
              <h2 className={h2Style}>
                The Price of a Bad Business Reputation
              </h2>
            </Link>
            <p className={pStyle}>
              Brand reputation and perception is an important and often over looked aspect of business's success.
            </p>
            <Link to="/bad-business-reputation">
              <button className="w-full bg-site-red hover:bg-red-800 text-sm text-white uppercase tracking-wider mt-6 p-3">
                Read More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default SectionBlog
